<script lang="ts" setup>
import { MessageOrId, useMsgFormatter } from "../../i18n";
import WuxBanner from "../WuxBanner/WuxBanner.vue";
import WuxBottomBar from "../WuxBottomBar/WuxBottomBar.vue";
import WuxButton from "../WuxButton/WuxButton.vue";
import WuxTile from "../WuxTile/WuxTile.vue";

export type DeadEndAction = {
    labelMsg: MessageOrId;
    isDisabled?: boolean;
    onClick?: () => void;
};

const props = defineProps<{
    titleMsg: MessageOrId;
    contentMsg: MessageOrId;
    primary?: DeadEndAction;
    secondary?: DeadEndAction;
    footNoteMsg?: MessageOrId;
}>();

const { m } = useMsgFormatter();
</script>

<template>
    <WuxBanner class="wux-dead-end">
        <WuxTile>
            <h1>
                {{ m(props.titleMsg) }}
            </h1>
            <p>{{ m(props.contentMsg) }}</p>
            <small v-if="props.footNoteMsg" class="wux-dead-end__footnote">{{ m(props.footNoteMsg) }}</small>
            <WuxBottomBar v-if="props.primary || props.secondary" isComponentLevel>
                <WuxButton
                    v-if="props.secondary"
                    variant="text"
                    type="button"
                    :isDisabled="props.secondary?.isDisabled"
                    :labelMsg="props.secondary.labelMsg"
                    @click="props.secondary.onClick"
                />
                <WuxButton
                    v-if="props.primary"
                    isPrimary
                    variant="text"
                    type="button"
                    :isDisabled="props.primary?.isDisabled"
                    :labelMsg="props.primary.labelMsg"
                    @click="props.primary.onClick"
                />
            </WuxBottomBar>
        </WuxTile>
    </WuxBanner>
</template>

<style lang="scss">
.wux-dead-end {
    &.wux-banner {
        padding-top: 5.5rem;
    }

    .wux-tile {
        margin: 0 auto;
        min-width: 20rem;
        max-width: 26rem;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 4px 1px;
    }

    &__footnote {
        display: block;
        margin-top: 2em;
        padding-bottom: 1em;
    }
}
</style>
