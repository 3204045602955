<script lang="ts" setup>
import { useSlots } from "vue";
const slots = useSlots();

type WuxBottomBarProps = {
    /**
     * If true, it will not be positioned "fixed"
     */
    isComponentLevel?: boolean;
};
const props = defineProps<WuxBottomBarProps>();
/*
  We are reversing the DOM order of items in the bottom bar
  to force the right most button (primary) to get the first focus.
  The intended visual order is achieved with CSS (flex-direction: row-reverse).
*/
</script>
<template>
    <div class="wux-bottom-bar" :class="{ 'wux-bottom-bar--page': !props.isComponentLevel }">
        <div class="actions">
            <component v-for="(node, index) in slots.default?.().reverse()" :key="index" :is="node" />
        </div>
    </div>
</template>

<style lang="scss">
@use "../../../assets/styles/variables.scss";
.wux-bottom-bar {
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid var(--wawi-color-neutral-500);
    background-color: var(--wawi-color-grayscale-white);
    z-index: variables.z("bottom-bar");
    height: var(--wux-bottom-bar-height);
    position: var(--wux-bottom-bar-position, relative);
    overflow: auto;
    &--page {
        position: fixed;
    }

    .actions {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow: auto;
        align-items: center;
        height: 100%;

        .wux-button {
            border-radius: 0;
            --_wux-button-font-size: 16px;
            overflow: hidden;
            .wux-button__label {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
/**
 * Hide the bottom bar behind the sidebar blocking overlay.
 */
body:has(.wux-sidebars__overlay) {
    .wux-bottom-bar--page {
        z-index: variables.z("bottom-bar-blocked");
    }
}
</style>
